import React from 'react'
import styled from 'styled-components'

const SwitchBox = styled.div`
  width: 28px;
  height: 16px;
  background:#eee;
  border-radius: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  &.checked{
    background:blue;
    .toggle{
      transform: translateX(100%);
    }
  }
`
const SwitchBoxTruck = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 90px;
  position: absolute;
  left:2px;
  top:2px;
  background:#fff;
  box-shadow:0 3px 20px rgba(0,0,0,.1);
  transition: .4s;
`

export default function SwitchInput({onChange, value = true, ...rest}) {
  return (
    <SwitchBox {...rest} onClick={()=>onChange(!value)} className={value ? 'checked' : ''}>
      <SwitchBoxTruck className="toggle" />
    </SwitchBox>
  )
}
