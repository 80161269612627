import React from 'react';
import styled from 'styled-components'

export default function Progress(props) {
	const { total, progress, hasPercentage, prompt } = props
	let percentage = progress / total * 100

	const ProgressMain = styled.div`
		position: relative;
		display: flex;
		height: 6px;
		align-items: center;
		margin-top:10px;
`

	const ProgressBJ = styled.div`
		height: 6px;
		background: #dedede;
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		flex:1;
	`

	const ProgressBox = styled.div`
		background: blue;
		position: absolute;
		left:0;
		right: 0;
		top:0;
		bottom: 0;
		transition: 2s;
		transform:translateX(${percentage-100}%);
		border-radius: 10px
	`

	const PercentageBox = styled.div`
		font-size: 10px;
		margin-left:10px;
	`
	const PromptBox = styled.span`
		color: red;
	`

	return (
		<ProgressMain>
			<ProgressBJ>
				<ProgressBox />
			</ProgressBJ>
			{<PercentageBox>
				{prompt && <PromptBox>{prompt}</PromptBox>}
				{hasPercentage && <span> {progress}/{total}</span>}
			</PercentageBox>}
		</ProgressMain>
	)
}