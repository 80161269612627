import React  from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const MessageWrap = styled.div``
const MessageIcon = styled.div``
const CloseIcon = styled.div``
const MessageTitle = styled.h3``
const MessageContent = styled.div``

export function Message(props) {
  return (
    <MessageWrap className="message">
      <MessageIcon className={`ico ico-${props?.icon}`} />
      <div className="message-container">
        <MessageTitle className="message-title">{ props?.title}</MessageTitle>
        <MessageContent className="message-content">{ props?.content}</MessageContent>
      </div>
      <CloseIcon className="close ico-x" onClick={ props?.onCancel} />
    </MessageWrap>
  )
}

export const message = props =>
  new Promise(() => {
    const holder = document.createElement('div')
    holder.setAttribute('class','message-box')
    document.body.appendChild(holder)
    const close = () => document.body.removeChild(holder)
    ReactDOM.render(
      <Message
        {...props}
        onCancel={close}
        onConfirm={close}
      />,
      holder
    )
  })
