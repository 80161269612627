import React from 'react'
import styled from 'styled-components'

const  TipsCointainer = styled.div`
  position: relative;
  cursor: pointer;
  &:hover{
    .tip{
      display: flex;
    }
  }
 
`
const  TipsContent = styled.div`
  display: inline-block;
`
const  TipsBox = styled.div`
  color:#fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:after{
    content:'';
    border: 5px solid transparent;
    border-top-color:rgba(0,0,0,.8);
  }
`
const  TipsModal = styled.div`
  display:none;
  font-size:12px;
  text-align:center;
  font-weight:normal;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  color:#fff;
  position:absolute;
  left:-100%;
  right:-100%;
  bottom:0;
  padding:14px 10px;
`

const TipsModalText = styled.div`
  border-radius:5px;
  background-color:rgba(0,0,0,.8);
  padding:5px 10px;
`

export default function Tips({children,text,contentStyle={},...rest}) {
  return (
    <TipsCointainer {...rest}>
      <TipsContent>{children}</TipsContent>
      {text && <TipsModal className="tip">
        <TipsBox style={contentStyle} className="box">
          <TipsModalText>{text}</TipsModalText>
        </TipsBox>
      </TipsModal>}
    </TipsCointainer>
  )
}
